<template lang="pug">
  section.pt-72.md-pt-144.px-12.hover-text-gray-500.pointer-events-none
    //- projects
    article.relative.flex(v-for="(prj, i) in prjsByMuralDate", :key="i", v-if="prj.data")
      //- title
      router-link.flex-1.lg-w-9x18.block.px-12.text-16.leading-looser.font-medium.hover-text-black.trans-color-fast.pointer-events-auto(:to="{name: 'project', params: {uid: prj.uid}}")
        h3 <i>{{ txt(prj.data.title) }}</i>, {{ txt(prj.data.location) }}, {{ prjYear(prj) }}
      //- hover image
      figure.hidden.md-block.w-4x18.pl-24.pr-12.opacity-0.order-first
        .relative(v-if="prj.data._index_image")
          resp-img.absolute.w-full(:lazy="true", :src="prj.data._index_image.url", :alt="prj.data._index_image.alt", :blurLoad="false")
</template>

<script>
import RespImg from '@/components/RespImg'
import { prjYear } from '@/utils'
import get from 'lodash/get'
export default {
  name: 'ProjectsByText',
  props: ['prjs'],
  data () {
    return {
      txt: this.$prismic.richTextAsPlain,
      prjYear
    }
  },
  computed: {
    prjsByMuralDate () {
      return this.prjs.slice().sort((a, b) => {
        a = new Date(get(a, 'data.mural_completion_date'))
        b = new Date(get(b, 'data.mural_completion_date'))
        return a < b ? 1 : a > b ? -1 : 0
      })
    }
  },
  components: { RespImg }
}
</script>

<style scoped>
figure{ transition: opacity 100ms; }
article:hover figure { transition: opacity 300ms 100ms; }

@media (hover:hover) {
  article a:hover + figure{ opacity:1; }
}
</style>
