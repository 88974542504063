<template>
  <section class="h-screen pt-72 md-pt-144 flex">
    <div class="relative bg-gray-100 w-full overflow-hidden">
      <!-- Map -->
      <gmap-map id="prj-map" :center="center" :zoom="zoom" style="width: 100%; height: 100%" :options="options" @zoom_changed="onZoomChanged">
        <!--<gmap-cluster imagePath="/img/gmaps/cluster-icons/" :imageSizes="[30, 36]" imageExtension="svg">-->
        <gmap-cluster :styles="clusterStyles" @click="onClusterClick" @dblclick="listVisible = false">
          <gmap-marker v-for="(m, i) in markers"
            :position="m.position"
            :title="m.title"
            :icon="markerIcon"
            :clickable="true"
            @click="onMarkerClick(m)"
            @mouseover="onMarkerHover(m)"
            :key="i"
            ></gmap-marker>
        </gmap-cluster>
      </gmap-map>
      <!-- Projects List -->
      <!-- TODO - add v-swipe gestures to hide panel -->
      <aside class="fixed right-0 bottom-0 max-h-67vh z-10 w-full pt-72 md-pt-0 md-absolute md-h-full md-max-h-none md-w-1x2 lg-w-7x18 flex md-pl-12 bg-white trans-quick" :class="{'transl-y-100 md-transl-x-100': !listVisible}">
        <header class="md-hidden absolute z-10 bg-white w-full top-0 left-0 h-72 flex items-center justify-center">
          <button class="p-6 block" aria-label="Close List" @click="listVisible = false">
            <img src="@/assets/icons/arrow-down.svg" alt="Down Arrow" class="select-none">
          </button>
        </header>
        <div class="relative w-full px-12 md-pr-24 overflow-y-scroll overflow-x-hidden scrolling-touch ">
          <article v-for="(prj, i) in activePrjs" :key="i" class="py-24 md-pt-0 trans-quick" :class="{'-mt-24 md-mt-0': !i, 'border-t md-pt-12': i > 0}">
            <div class="w-grid-24 -ml-24 px-12" >
              <router-link :to="{name: 'project', params: {uid: prj.uid}}" class="flex">
                <header class="w-1x2 px-12">
                  <h4 class="text-15 md-text-16 font-medium italic">{{txt(prj.data.title)}}</h4>
                  <h6 class="text-15 md-text-16 font-medium">{{txt(prj.data.location)}}, {{prj.data.year}}</h6>
                  <prismic-rich-text class="text-12 mt-em md-mt-24" :field="prj.data.summary" />
                </header>
                <figure class="w-1x2 px-12" v-if="prj.data._index_image" :key="prj.data._index_image.url">
                  <div class="relative" :class="prj.data._ar_class">
                    <resp-img :bg="true" :lazy="true" :src="prj.data._index_image.url" :alt="prj.data._index_image.alt" />
                  </div>
                </figure>
              </router-link>
            </div>
          </article>
        </div>
        <!-- zoom btns -->
        <nav class="hidden md-block absolute p-24 bottom-0 left-0" style="transform:translateX(-100%)">
          <div class="flex" style="box-shadow: 0 2px 5px rgba(0,0,0,0.1)">
            <button><img class="select-none" src="@/assets/icons/zoom-minus.svg" @click="zoom--" /></button>
            <button><img class="select-none" src="@/assets/icons/zoom-plus.svg" @click="zoom++" /></button>
          </div>
        </nav>
      </aside>
    </div>
  </section>
</template>

<script>
import theme from './Map/google-maps-theme'
import RespImg from '@/components/RespImg'
export default {
  name: 'ProjectsByMap',
  props: ['projects'],
  data () {
    return {
      center: { lat: 30, lng: -20 },
      zoom: 3,
      options: {
        styles: theme,
        fullscreenControl: false,
        gestureHandling: 'greedy',
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 3,
        zoomControl: false
        // zoomControlOptions: { position: 1 } // 1 = TOP LEFT
      },
      markerIcon: '/img/gmaps/marker.svg',
      clusterStyles: [
        { url: '/img/gmaps/cluster-icons/1.svg', width: 30, height: 30, textColor: 'white', fontFamily: 'basis-grotesque-pro, sans-serif', fontWeight: 400 }
      ],
      // UI
      txt: this.$prismic.richTextAsPlain,
      listVisible: false,
      active: []
    }
  },
  computed: {
    prjs () {
      return this.projects.filter(prj => prj && prj.data.location__lat && prj.data.location__lng)
    },
    markers () {
      return this.prjs.map(prj => {
        return {
          title: this.txt(prj.data.title),
          position: { lat: prj.data.location__lat, lng: prj.data.location__lng }
        }
      })
    },
    activePrjs () {
      return this.prjs.filter(prj => this.active.includes(this.txt(prj.data.title)))
    }
  },
  methods: {
    onMarkerClick (m) {
      this.listVisible = true
      // this.center = m.position
      this.active = [m.title]
    },
    onMarkerHover (m) {
      if (this.listVisible) this.active = m.title
    },
    onClusterClick (e) {
      // find cluster based on center (oddly, not returned outright)
      const cluster = e.markerClusterer_.clusters_.find(cl => {
        const same = key => cl.center_[key]() === e.center_[key]()
        return same('lat') && same('lng')
      })
      if (!cluster) return
      this.active = cluster.markers_.map(m => m.title)
      this.listVisible = this.active.length > 0
      // this.center = e.center_
    },
    onZoomChanged (val) {
      this.listVisible = false
      this.zoom = val
    }
  },
  components: { RespImg }
}
</script>

<style>
#prj-map{
  transition: filter 400ms;
}
.blur-media #prj-map{
  transition: filter 400ms;
  filter: blur(6px);
}
</style>
