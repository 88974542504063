import { render, staticRenderFns } from "./ProjectsByText.vue?vue&type=template&id=5a6a3ab1&scoped=true&lang=pug&"
import script from "./ProjectsByText.vue?vue&type=script&lang=js&"
export * from "./ProjectsByText.vue?vue&type=script&lang=js&"
import style0 from "./ProjectsByText.vue?vue&type=style&index=0&id=5a6a3ab1&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6a3ab1",
  null
  
)

export default component.exports