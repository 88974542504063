<template lang="pug">
  article
    .bg-white.min-h-screen
      transition-group(:name="!entering ? 'pagefade' : 'none'", v-on:after-enter="$emit('afterEnter')")
        projects-by-image(v-if="view === 'image' && projects.length", key="image")
        projects-by-map(v-if="view === 'map'", key="map", :projects="projects")
        projects-by-text(v-if="view === 'text'", key="text", :prjs="projects")
</template>

<script>
import ProjectsByImage from '@/components/ProjectsByImage'
import ProjectsByMap from '@/components/ProjectsByMap'
import ProjectsByText from '@/components/ProjectsByText'
import { mapState } from 'vuex'
export default {
  name: 'Projects',
  props: ['entering'],
  computed: {
    ...mapState(['projects']),
    view () {
      return this.$route.query.view || 'image'
    }
  },
  watch: {
    entering (enter) {
      // jump to top after entering if skipped scrollBehavior
      if (!enter && this.$route.query.s === 0) {
        this.$router.replace({ query: {} })
        this.$nextTick(() => window.scroll(0, 0))
      }
    }
  },
  created () {
    this.$Progress.start()
    this.$store.dispatch('getProjects').then(() => this.$Progress.finish())
  },
  components: { ProjectsByImage, ProjectsByMap, ProjectsByText }
}
</script>

<style>
</style>
